:root {
  --bs-primary: #ff5500d3;
  --bs-secondary: #787878;
  --bs-pagination-active-bg:#ff5500d3 !important;
  --bs-pagination-color:#ff5500d3 !important;
}
.btn-info{
  --bs-btn-bg: #cc1616;
  --bs-btn-border-color: #cc1616;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cc1616;
  --bs-btn-hover-border-color: #cc1616;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-color:white;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg:  #cc1616;
  --bs-btn-active-border-color: #cc1616;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #cc1616;
  --bs-btn-disabled-border-color: #cc1616;
}

.page-link, .active>.page-link, .page-link.active{
  --bs-primary: #ff5500d3;
  --bs-secondary: #787878;
  --bs-pagination-active-bg:#ff5500d3 !important;
  --bs-pagination-color:#ff5500d3 !important;

}

.signature-pad {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: 200px;
  width: 100%;
  max-width: 500px;
}

.card-transparent {
  background: none;
}

.sign-bg {
  position: absolute;
  top: unset;
  left: auto;
  text-align: center;
  padding-top: 5%;
  opacity: .1;
}

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}


.content-area {
  flex: 1;
  padding: 20px; /* Adjust padding as needed */
  overflow-y: auto;
}

.global-header {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  text-align: center;
}

#wrapper {
  overflow-x: scroll;
  min-width: 250px;
}

#sidebar-wrapper {
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

.subheader {
  background-color: var(--bs-secondary);
  color: white;
  padding: 0px 20px;
}

.subheader nav {
  display: flex;
  justify-content: center;
}

.subheader ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.subheader li {
  margin: 0 0px;
}

.subheader a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  margin-left: 2px;
  font-size: 16px;
  font-family: var(--nav-font);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
}

.subheader a:hover {
  text-decoration: none;
  color: var(--contrast-color);
  background-color: var(--nav-hover-color);
}
.subheader a.active {
  color: var(--contrast-color);
  background-color: var(--nav-hover-color);
}


.scroll-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  /*height: 100%;*/
  cursor: pointer;
  color: white;
  font-size: 21px;
  background-color: rgba(252, 16, 16, 0.5);
  position: absolute;
  /*top: 0;*/
  bottom: 0;
  z-index: 1;
}

.scroll-arrow.left {
  left: 0;
}

.scroll-arrow.right {
  right: 0;
}

@media (max-width: 768px) {
  .subheader{
    overflow-x:scroll;
  }
  .subheader ul{
    justify-content: normal;

  }
  .subheader ul li a {
    padding: 5px 10px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.flag-icon {
  max-width: 20px;
}

.language-select ul li {
  min-width: unset !important;
}

.language-select button {
  font-size: 28px;
}


/** LOGIN SECTION */
.login-content
{
  padding: 0;
}
.login-image
{
  display: flex ;
}

section.login-content 
{
  background-color: #333 !important;
  color:white !important;
}

.login-content .card
{
  color:white !important;
}

.login-content .form-control, .login-content .form-control:active , .form-control:focus
{
  background-color: #fff5f5  !important;
  background:#fff5f5 !important;
}


.sidebar {
  position: fixed;
  top: 64px;
  left: 0;
  bottom: 0;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 5px 5px 5px 0px rgba(212, 30, 30, 0.1);
  background-color: #fff;
}
.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}
.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}
.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #e42021;
  transition: 0.3;
  background: #f6f9ff;
  padding: 10px 15px;
  border-radius: 4px;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: rgba(228, 32, 33, 1);
}



.sidebar {
  width: 250px; /* Adjust the width as needed */
  overflow-y: visible !important;
}
/* Toggle sidebar button styling */
.sidebar-toggle-btn {
  padding: 0px;
  text-align: center;
  position: absolute;
  top: 5px;
  transition: transform 0.3s ease-in-out;
  left: 240px;
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 5px 5px 5px 0px rgba(212, 30, 30, 0.1);
  background-color: #fff;
}

.sidebar-toggle-btn .btn {
  background: none;
  border: none;
  cursor: pointer;

}

/* Collapse sidebar */
.toggle-sidebar .sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

/* Make sure the toggle button remains visible when sidebar is collapsed */
.toggle-sidebar .sidebar-toggle-btn 
{
  z-index: 1000;
}


/* NEW Leaf items for whatsapp */

ul.leaf-item
{
  padding-left: 0;
  border-top-width: 1px ;
  border-top-color:   rgb(222, 226, 230);
  border-top-style: solid;
}
 .leaf-item
{
    margin-bottom: 5px;
    display: block;
    padding: 10px 0px 5px 15px;  
    font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: 0 0;

  
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
 .leaf-item i
{
  font-size: 16px;
  margin-right: 10px;
  font-weight:bold ;
  color: rgba(228, 32, 33, 1);
  
}

.badge
{
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: rgba(228, 32, 33, 1);
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
  margin-left:5px;
  float:right;
  margin-top:3px;
}

@media (min-width: 1200px) {
  #main, #footer {
      /*margin-left: 300px;*/
  }
}
#main {
  /*margin-top: 60px;*/
  padding: 20px 30px;
  transition: all 0.3s;
}

.sidebar-nav .nav-link.collapsed {
  color: #012970;
  background: #fff;
}

.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}


.dashboard .info-card {
  padding-bottom: 10px;
}
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.card-body {
  padding: 0 20px 20px 20px;
}

.card-title {
  padding: 20px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}



.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}
.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}
.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

a {
  color: #E42021;
  text-decoration: none !important;
}
footer a {
  color: #ff3e3e;
}

 .dashboard footer
{
  padding-left: 250px;
  transition: all 0.5s ease;;

}
.dashboard.toggle-sidebar footer
{
  padding-left:0px;
  transition: all 0.5s ease;
}

.pagetitle {
  margin-bottom: 10px;
}


/* Datatable */
.datatable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.datatable-top, .datatable-bottom {
  padding: 8px 10px;
}
.datatable-top > nav:first-child, .datatable-top > div:first-child, .datatable-bottom > nav:first-child, .datatable-bottom > div:first-child {
  float: left;
}
.datatable-top > nav:last-child, .datatable-top > div:not(first-child), .datatable-bottom > nav:last-child, .datatable-bottom > div:last-child {
  float: right;
}
.datatable-selector {
  padding: 6px;
}

.datatable-input {
  padding: 6px 12px;
}
.datatable-wrapper.no-footer .datatable-container {
  border-bottom: 1px solid #d9d9d9;
}
.datatable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}.dashboard .recent-sales .table thead th {
  border: 0;
}

.datatable-table th button, .datatable-pagination-list button {
  color: inherit;
  border: 0;
  background-color: inherit;
  cursor: pointer;
  text-align: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.datatable-sorter::before {
  border-top: 4px solid #000;
  bottom: 0px;
}
.datatable-sorter::before, .datatable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}


.datatable-table > tbody > tr > td, .datatable-table > tbody > tr > th, .datatable-table > tfoot > tr > td, .datatable-table > tfoot > tr > th, .datatable-table > thead > tr > td, .datatable-table > thead > tr > th {
  vertical-align: top;
  padding: 8px 10px;
}

.datatable-top, .datatable-bottom {
  padding: 8px 10px;
}


/**LANDING PAGE STYLES**/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #333;
}

h1, h2, h3, h4, h5, .heading-section h2 {
  font-family: 'Poiret one','Open Sans', sans-serif;
  font-weight: 700;
}

p, label, input, textarea, button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

button {
  background: rgba(228, 32, 33, 0.8);
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 15px 25px;
  transition: all 0.3s ease-out;
}

  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: rgba(228, 32, 33, 0.8);
    --bs-btn-border-color: rgba(228, 32, 33, 1);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgba(228, 32, 33, .9);
    --bs-btn-hover-border-color: rgba(228, 32, 33, 1);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(226, 37, 37);
    --bs-btn-active-border-color: rgb(226, 37, 37);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: rgba(228, 32, 33, 0.6);;
    --bs-btn-disabled-border-color: rgba(228, 32, 33, .9);;
}


button:hover {
  background: rgba(228, 32, 33, 1);
}
button.btn-close:hover{
  background: initial;;
  --bs-btn-close-color: #000;
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
}

.form-group input, .form-group textarea {
  width: 100%;
  border: none;
  background: #d9d9d9;
  padding: 5px;
  margin-bottom: 5px;
}

#error, #ajaxsuccess {
  display: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}



/**FLOATING ALERTS***/

.alert
{
  float: right;
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 10000;
}